import { Component, input, output } from '@angular/core';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PasswordValidators } from 'ngx-validators';
import { TranslocoPipe } from '@jsverse/transloco';

import { FieldWithErrorsComponent } from 'src/app/shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from 'src/app/shared/components/form-with-errors/form-with-errors.component';

@Component({
  selector: 'win-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [
    '../../../../shared/styles/authentication.scss',
    './confirm.component.scss',
  ],
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ConfirmComponent {
  readonly spinnerState = input.required<boolean>();
  readonly confirm = output<{
    new_password1: string;
    new_password2: string;
  }>();

  passwordsForm = new FormGroup(
    {
      new_password1: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ],
      }),
      new_password2: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ],
      }),
    },
    {
      validators: [
        PasswordValidators.mismatchedPasswords(
          `new_password1`,
          `new_password2`,
        ),
      ],
    },
  );

  onSubmit(): void {
    if (this.passwordsForm.valid) {
      this.confirm.emit({
        new_password1: this.passwordsForm.get('new_password1').value,
        new_password2: this.passwordsForm.get('new_password2').value,
      });
    }
  }
}
