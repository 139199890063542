import { Component, inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  submitOfflineOrders,
  toggleOfflineMode,
} from 'src/app/store/offline-mode/offline-mode.actions';

import { OfflineModeSpinnerComponent } from '../offline-mode-spinner/offline-mode-spinner.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { offlineModeFeature } from 'src/app/store/offline-mode/offline-mode.state';

@Component({
  selector: 'win-offline-mode-error-modal',
  templateUrl: './offline-mode-error-modal.component.html',
  styleUrls: ['./offline-mode-error-modal.component.scss'],
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class OfflineModeErrorModalComponent {
  private readonly dialog = inject(MatDialog);
  private readonly dialogRef =
    inject<MatDialogRef<OfflineModeErrorModalComponent>>(MatDialogRef);
  private readonly store = inject(Store);

  hasError$ = this.store.select(offlineModeFeature.selectHasError);

  constructor() {
    this.dialogRef.disableClose = true;
  }

  resubmitOrders() {
    this.dialogRef.close();
    this.dialog.open(OfflineModeSpinnerComponent);
    this.store.dispatch(submitOfflineOrders());
  }

  close() {
    this.dialogRef.close();
    this.store.dispatch(toggleOfflineMode({}));
  }
}
