import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, of, switchMap, tap, throwError } from 'rxjs';
import { AuthenticationServiceBuilder } from '../services/authentication/authentication.builder';
import {
  logoutCompletely,
  setRedirectionUrl,
} from 'src/app/store/authentication/authentication.actions';

enum ErrorKeys {
  MISSING_LANG = 'reports.errors.param-missing-lang',
  MISSING_REPORT = 'reports.errors.param-missing-report',
  INVALID_URL = 'reports.errors.invalid-url',
  INCOMPLETE_URL = 'reports.errors.incomplete-url',
}

const buildUrl = (nextUrl: string, accessToken: string): string => {
  try {
    const url = new URL(decodeURIComponent(nextUrl));
    if (!url.searchParams.has('lang')) throw new Error(ErrorKeys.MISSING_LANG);
    if (!url.searchParams.get('report'))
      throw new Error(ErrorKeys.MISSING_REPORT);
    url.searchParams.set('token', accessToken);
    return url.toString();
  } catch (error: unknown) {
    throw new Error(ErrorKeys.INVALID_URL);
  }
};

const navigateToUrlNewTab = (location: Location, url: string) => {
  const newWindow = window.open(url);
  if (newWindow) {
    setTimeout(() => {
      location.back();
    }, 100);
  }
};

export const redirectResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);
  const authServiceBuilder = inject(AuthenticationServiceBuilder);
  const location = inject(Location);

  const paramNext = route.queryParamMap.get('next');
  const shouldOpenNewTab =
    (router.getCurrentNavigation()?.extras?.state?.newTab as boolean) ?? false;

  if (paramNext) {
    const service = authServiceBuilder.getService();
    return service.refreshToken(service.getRefreshToken()).pipe(
      tap(({ access, refresh }) => {
        service.saveAccessToken(access, refresh);
      }),
      switchMap(({ access }) => {
        try {
          const url = buildUrl(paramNext, access);
          store.dispatch(setRedirectionUrl({ url }));
          if (shouldOpenNewTab) {
            navigateToUrlNewTab(location, url);
          } else {
            document.location.href = url;
          }
        } catch (error: unknown) {
          router.navigate([`redirect`], {
            state: {
              error: (error as Error).message,
              back: shouldOpenNewTab,
            },
          });
        }
        return of(true);
      }),
      catchError((error: unknown) => {
        if ((error as HttpErrorResponse)?.status === 401) {
          store.dispatch(logoutCompletely());
          return of(null);
        }
        return throwError(() => new HttpErrorResponse({ error }));
      }),
    );
  } else if (!router.getCurrentNavigation()?.extras?.state?.error) {
    router.navigate([`redirect`], {
      state: {
        error: ErrorKeys.INCOMPLETE_URL,
        back: shouldOpenNewTab,
      },
    });
  }
  return of(true);
};
