import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { resendEmailConfirmation } from 'src/app/store/authentication/authentication.actions';
import { usersFeature } from 'src/app/store/user/user.state';

@Component({
  selector: 'win-verify-email',
  templateUrl: './verify-email.container.html',
  styleUrls: ['./verify-email.container.scss'],
  imports: [AsyncPipe, MatButtonModule, RouterLink, TranslocoPipe],
})
export class VerifyEmailComponent {
  private readonly store = inject(Store);

  emailResent = false;

  userFullName$ = this.store.pipe(select(usersFeature.selectUserFullName));
  userEmail$ = this.store
    .pipe(select(usersFeature.selectUserEmail))
    .pipe(map((email) => ({ email })));

  resendEmail(): void {
    this.emailResent = true;
    this.store.dispatch(resendEmailConfirmation());
  }
}
