import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { API_CONFIG } from 'src/app/app.config';
import { setOrders } from 'src/app/store/orders/orders.actions';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  FetchOrdersParams,
  GeneralRepeatOrdersData,
  Order,
  RepeatOrder,
  RepeatOrderData,
} from 'src/app/shared/models/orders';
import { User } from 'src/app/shared/models/user';

import { OrdersService } from './orders.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { usersFeature } from 'src/app/store/user/user.state';

@Injectable({ providedIn: 'root' })
export class OfflineOrdersService implements OrdersService {
  private readonly utilsService = inject(UtilsService);
  private readonly store = inject(Store);

  private readonly ordersEP = `${API_CONFIG.orderTakingApi}/orders/`;
  private readonly repeatEP = `${API_CONFIG.orderTakingApi}/orders_repeat/`;
  private readonly getUserEP = (id: number) =>
    `${API_CONFIG.orderTakingApi}/users/${id}/`;
  private readonly getConsumerEP = (id: number) =>
    `${API_CONFIG.orderTakingApi}/consumers/${id}/`;
  private readonly getProductSheetEP = (token: string) =>
    `${API_CONFIG.mealPlanningApi}/public/products/${token}`;

  getUser = (id: number) => this.utilsService.get<User>(this.getUserEP(id));

  getConsumer = (id: number) =>
    this.utilsService.get<Consumer>(this.getConsumerEP(id));

  fetchMenuSource = (url: string, params = {}) => {
    const menuSource = JSON.parse(
      localStorage.getItem('menu-source') || '{}',
    ) as Record<string, string>;
    return of(menuSource[url]);
  };

  fetchProductSheetSource = (token, params = {}) => of('');

  createRepeatOrder = (data: RepeatOrderData) =>
    this.utilsService.post<RepeatOrderData, RepeatOrder>(this.repeatEP, data);

  fetchOrders = (params: Partial<FetchOrdersParams>) => {
    const createdOrders = JSON.parse(
      localStorage.getItem('created-orders') || '{}',
    ) as Record<string, { data: { items: Order[] } }>;
    return of(createdOrders).pipe(
      withLatestFrom(this.store.select(usersFeature.selectCurrentConsumer)),
      switchMap(([orders, consumer]) => {
        const selectedOrders = orders[
          consumer.uuid + '--' + params.menu
        ]?.data.items.filter(
          (order) =>
            order.menu === params.menu &&
            order.date === params.date &&
            order.consumer === consumer.id,
        );
        this.store.dispatch(setOrders({ orders: selectedOrders ?? [] }));
        return of(selectedOrders);
      }),
    );
  };

  fetchRepeatOrders = (
    params: Partial<FetchOrdersParams>,
  ): Observable<RepeatOrder[]> => of([] as RepeatOrder[]);

  setGeneralRepeatOrders = (url: string, data: GeneralRepeatOrdersData) =>
    this.utilsService.post<GeneralRepeatOrdersData, RepeatOrder[]>(url, data);

  fetchOrdersCount = (params: Partial<FetchOrdersParams>) => of([]);
}
