import { Component, inject, input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ManagableAccess } from 'src/app/shared/models/consumers';
import { ManagableOrder, Order } from 'src/app/shared/models/orders';

import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe, DatePipe } from '@angular/common';
import { globalFeature } from 'src/app/store/global/global.state';

@Component({
  selector: 'win-order-date',
  templateUrl: './order-date.component.html',
  styleUrls: ['./order-date.component.scss'],
  imports: [AsyncPipe, DatePipe, TranslocoPipe],
})
export class OrderDateComponent {
  private readonly store = inject(Store);

  readonly item = input.required<ManagableOrder | Order | ManagableAccess>();
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
}
