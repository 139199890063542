import { Location, AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';
import { ReportActions } from 'src/app/store/reports/report.actions';

interface RedirectError {
  error: string;
  back: boolean;
}

@Component({
  selector: 'win-redirect',
  templateUrl: './redirect.container.html',
  styleUrls: ['./redirect.container.scss'],
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class RedirectContainer {
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  redirectionUrl$ = this.store.select(
    authenticationFeature.selectRedirectionUrl,
  );

  currentError: RedirectError | undefined;

  constructor() {
    const route = this.router.lastSuccessfulNavigation;
    this.currentError = route?.extras?.state?.error
      ? (route.extras?.state as RedirectError)
      : undefined;
  }

  navigate(): void {
    if (this.currentError.back) {
      this.location.back();
    } else {
      this.router.navigate(['']);
      // the reports are not fetched if you land on the redirect page directly
      this.store.dispatch(ReportActions.fetchReports());
    }
  }
}
