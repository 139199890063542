<mat-toolbar class="toolbar">
  <div class="logo-wrapper">
    <a
      routerLink="/"
      [queryParamsHandling]="simpleConsumer() ? 'preserve' : null"
      aria-label="Home"
      ><img
        class="logo-img"
        [height]="logo() ? 50 : 40"
        [src]="logo() ?? 'img/logo_dark.svg'"
        alt="Logo"
    /></a>
  </div>
  <div class="pickers-wrapper">
    <win-language-picker
      [currentLang]="currentLang()"
      [isOffline]="isOffline()"
      (languageChange)="languageChange.emit($event)"
    ></win-language-picker>
    <win-font-size-picker></win-font-size-picker>
    @if (showOfflineMode()) {
      <win-offline-mode [orderCount]="offlineOrderCount()"></win-offline-mode>
    }
  </div>
</mat-toolbar>
