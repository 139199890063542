import { inject, Injectable } from '@angular/core';
import { AMQPChannel, AMQPWebSocketClient } from '@cloudamqp/amqp-client';
import { Store } from '@ngrx/store';
import { catchError, concatMap, EMPTY, Subject, Subscription } from 'rxjs';
import { WebsocketMessage } from '../../models/websocket';
import { reportWebSocketError } from 'src/app/store/user/user.actions';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private readonly exchange = 'amq.topic';
  private readonly maxRetryAttempts = 3;
  private readonly socketSubject$ = new Subject<string>();
  private readonly store = inject(Store);
  private readonly url = `wss://guiitkub:BD3jGb8kGS69vRm3OlyWB0-0YcvgEAxp@buzzing-black-squirrel.rmq4.cloudamqp.com/ws/amqp`;

  private queue = '';
  private amqp = this.setAmqpClient();
  private socketSubscription$?: Subscription;

  public connect(queue: string): Promise<void> {
    this.disconnect();
    if (queue) this.queue = queue;
    return this.start();
  }

  public disconnect(): void {
    if (this.socketSubscription$) this.socketSubscription$.unsubscribe();
    if (!this.amqp.closed) this.amqp.close();
  }

  public sendMessage(msg: WebsocketMessage): void {
    if (this.amqp.closed) {
      console.error('Cannot send message, WebSocket is closed');
      this.amqp = this.setAmqpClient();
    }
    this.socketSubject$.next(this.encode({ ...msg, deviceId: null }));
  }

  private setAmqpClient(): AMQPWebSocketClient {
    return new AMQPWebSocketClient(
      this.url,
      'websockets',
      'kds_service',
      '8X3Jkx-y=#,27yR',
      undefined,
      4096,
      30,
    );
  }

  private async start(retryCount = 0): Promise<void> {
    this.amqp.onerror = (err) => this.handleError(err, retryCount);
    try {
      const conn = await this.amqp.connect();
      const ch = await conn.channel();

      // Perform any necessary setup sequentially
      await ch.exchangeDeclare(this.exchange, 'topic');
      const q = await ch.queue('');
      await q.bind(this.exchange, this.queue);

      this.attachPublish(ch, retryCount);
    } catch (err) {
      console.error('Connection error:', err);
      this.handleError(err, retryCount);
    }
  }

  private async attachPublish(
    ch: AMQPChannel,
    retryCount: number,
  ): Promise<void> {
    this.socketSubscription$ = this.socketSubject$
      .pipe(
        concatMap(async (msg) => {
          if (this.amqp.closed) {
            this.handleError('AMQP connection is closed', retryCount);
          }
          if (ch.closed) {
            this.handleError('AMQP channel is closed', retryCount);
          }
          await ch.basicPublish(this.exchange, this.queue, msg, {
            contentType: 'application/json',
            expiration: '5000',
          });
        }),
        catchError((err: unknown) => {
          this.handleError(err, retryCount);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private readonly encode = (obj: unknown): string => JSON.stringify(obj);

  private handleError(err: unknown, retryCount: number): void {
    if (this.amqp.closed) {
      this.amqp = this.setAmqpClient();
    }
    console.error('Error', err, 'reconnecting in 1s');
    if (retryCount < this.maxRetryAttempts) {
      setTimeout(() => {
        this.start(retryCount + 1);
      }, 1000);
    } else {
      this.store.dispatch(reportWebSocketError());
    }
  }
}
