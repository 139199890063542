import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { withLatestFrom, filter, take } from 'rxjs';

import { ErrorBannerComponent } from '../../components/error-banner/error-banner.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { PreFooterComponent } from '../../components/pre-footer/pre-footer.component';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import { logout } from 'src/app/store/authentication/authentication.actions';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';
import { setGlobalLanguage } from 'src/app/store/global/global.actions';
import { globalFeature } from 'src/app/store/global/global.state';
import { offlineModeFeature } from 'src/app/store/offline-mode/offline-mode.state';
import { ReportActions } from 'src/app/store/reports/report.actions';
import { selectUrl } from 'src/app/store/router/router.selectors';
import { usersFeature } from 'src/app/store/user/user.state';

@Component({
  selector: 'win-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  imports: [
    AsyncPipe,
    RouterOutlet,
    ErrorBannerComponent,
    HeaderComponent,
    PreFooterComponent,
    FooterComponent,
  ],
})
export class MainLayoutComponent implements OnInit {
  private readonly store = inject(Store);

  emailVerified$ = this.store.pipe(select(usersFeature.selectEmailVerified));
  globalError$ = this.store.select(globalFeature.selectGlobalError);
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  initialUrl$ = this.store.pipe(select(selectUrl));
  isLoggedIn$ = this.store.pipe(select(authenticationFeature.selectIsLogged));
  isAgent$ = this.store.pipe(select(usersFeature.selectIsAgent));
  isManager$ = this.store.pipe(select(usersFeature.selectIsManager));
  isOffline$ = this.store.pipe(select(offlineModeFeature.selectValue));
  isUnauth$ = this.store.pipe(select(authenticationFeature.selectIsSimpleAuth));
  logo$ = this.store.pipe(select(usersFeature.selectLogo));
  offlineOrderCount$ = this.store.select(offlineModeFeature.selectOrderCount);
  showOfflineMode$ = this.store.select(
    offlineModeFeature.selectAllowOfflineMode,
  );
  simpleConsumer$ = this.store.pipe(select(usersFeature.selectSimpleConsumer));
  terminal$ = this.store.pipe(select(usersFeature.selectTerminal));
  userFullName$ = this.store.pipe(select(usersFeature.selectUserFullName));

  ngOnInit(): void {
    this.initialUrl$
      .pipe(
        withLatestFrom(this.isLoggedIn$),
        filter(([url, loggedIn]) => loggedIn && url !== undefined),
        take(1),
      )
      .subscribe(([url]) => {
        if (url.startsWith('/redirect') === false) {
          this.store.dispatch(ReportActions.fetchReports());
        }
      });
  }

  languageChange(value: keyof typeof LANGUAGES): void {
    this.store.dispatch(setGlobalLanguage({ payload: value }));
  }

  exitCompletely(): void {
    this.store.dispatch(logout());
  }
}
