<section class="wrapper">
  @if (unauthenticatedOrder) {
  <win-unauthenticated-login
    [aggregatedOrderFeature]="unauthAggregatedOrderTakingEnabled$ | async"
    [unauthInvalidUrlMessage]="unauthInvalidUrlMessage$ | async"
    [spinnerState]="unauthSpinnerState$ | async"
  >
  </win-unauthenticated-login>
  } @else {
  <span>
    <h1 class="mat-h1">{{'login.welcome' | transloco}}</h1>
    <p class="subtitle">{{'login.title' | transloco}}</p>
  </span>
  <win-simple-login
    [spinnerState]="spinnerState$ | async"
    (login)="onLogin($event)"
  ></win-simple-login>
  }
</section>
