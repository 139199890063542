import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { globalFeature } from 'src/app/store/global/global.state';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import { setGlobalLanguage } from 'src/app/store/global/global.actions';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'win-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  imports: [RouterOutlet, HeaderComponent, FooterComponent, AsyncPipe],
})
export class AuthLayoutComponent {
  private readonly store = inject(Store);
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));

  languageChange(value: keyof typeof LANGUAGES): void {
    this.store.dispatch(setGlobalLanguage({ payload: value }));
  }
}
