import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { usersFeature } from 'src/app/store/user/user.state';
import { OfflineOrder } from 'src/app/shared/models/orders';
import * as OfflineModeActions from './offline-mode.actions';

interface OfflineModeState {
  value: boolean;
  downloading: boolean;
  submitting: boolean;
  consumer: string;
  date: string;
  orders: Record<string, OfflineOrder>;
  orderCount: number;
  hasError: boolean;
}

const initialState: OfflineModeState = {
  value: false,
  downloading: false,
  submitting: false,
  consumer: null,
  date: null,
  orders: {},
  orderCount: 0,
  hasError: false,
};

const getOfflineOrdersCount = (obj: Record<string, OfflineOrder>): number => {
  return Object.entries(obj).filter(([, value]) => value?.offline).length;
};

const reducer = createReducer(
  initialState,
  on(OfflineModeActions.toggleOfflineMode, (state) => ({
    ...state,
    value: !state.value,
  })),
  on(OfflineModeActions.setOfflineConsumer, (state, { payload }) => ({
    ...state,
    consumer: payload,
  })),
  on(OfflineModeActions.setOfflineDate, (state, { payload }) => ({
    ...state,
    date: payload ? payload.toISOString().split('T')[0] : null,
  })),
  on(OfflineModeActions.setOfflineOrders, (state, { payload }) => ({
    ...state,
    orders: payload,
    orderCount: getOfflineOrdersCount(payload),
  })),
  on(OfflineModeActions.setOfflineDataDownloading, (state, { payload }) => ({
    ...state,
    downloading: payload,
  })),
  on(OfflineModeActions.setOfflineOrdersSubmitting, (state, { payload }) => ({
    ...state,
    submitting: payload,
  })),
  on(OfflineModeActions.setOfflineError, (state, { payload }) => ({
    ...state,
    hasError: payload,
  })),
  on(OfflineModeActions.setOfflineModeValue, (state, { payload }) => ({
    ...state,
    value: payload,
  })),
);

export const offlineModeFeature = createFeature({
  name: 'offlineMode',
  reducer,
  extraSelectors: () => ({
    selectAllowOfflineMode: createSelector(
      usersFeature.selectIsManager,
      usersFeature.selectIsTerminal,
      usersFeature.selectTerminalTypeCanteen,
      (isManager, isTerminal, isCanteenTerminal) =>
        isManager && isTerminal && !isCanteenTerminal,
    ),
  }),
});
