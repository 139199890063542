import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import Fuse from 'fuse.js';
import GLightbox from 'glightbox';
import {
  InterfaceLanguage,
  LANGUAGES,
} from 'src/app/shared/constants/languages';
import {
  DATA_ALLERGEN_CLASS,
  MENU_SEARCH_CLASS,
} from 'src/app/shared/constants/misc';
import { AnyAccessType, Consumer } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  SelectFieldModel,
  UnknownManagableItem,
  lightboxSelector,
} from 'src/app/shared/models/misc';
import {
  Consistency,
  extractOrderItems,
  isOrderMatchingElement,
  ManagableOrder,
  Order,
  OrderDescriptionsData,
  OrderVariant,
  RepeatOrder,
  SetOrdersItem,
  Texture,
} from 'src/app/shared/models/orders';
import { Terminal, UnauthConsumer } from 'src/app/shared/models/user';
import { omit } from 'lodash-es';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SaveAndGoComponent } from './save-and-go/save-and-go.component';
import { SaveStripeComponent } from '../../../../shared/components/save-stripe/save-stripe.component';
import { OrderDescriptionsComponent } from './order-descriptions/order-descriptions.component';
import { OrderDateComponent } from '../order-date/order-date.component';
import { RunScriptsDirective } from '../../../../shared/directives/run-scripts.directive';
import { ConsumerInfoComponent } from '../../../../shared/components/consumer-info/consumer-info.component';
import { NgClass } from '@angular/common';
import { SubNavigationComponent } from '../../../../shared/components/sub-navigation/sub-navigation.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'win-select-orders',
  templateUrl: './select-orders.component.html',
  styleUrls: ['./select-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatBottomSheetModule,
    SubNavigationComponent,
    ConsumerInfoComponent,
    RunScriptsDirective,
    NgClass,
    OrderDateComponent,
    SaveStripeComponent,
    SaveAndGoComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    TranslocoPipe,
    SafePipe,
    MatIconModule,
  ],
})
export class SelectOrdersComponent implements OnInit, OnChanges {
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly _bottomSheet = inject(MatBottomSheet);
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);

  readonly currentOrders = input.required<Order[]>();
  readonly currentOrdersRepeat = input.required<RepeatOrder[]>();
  readonly consumer = input.required<Consumer>();
  readonly consumerUnauth = input.required<UnauthConsumer>();
  readonly prevMenu = input.required<Menu | undefined>();
  readonly menu = input.required<Menu>();
  readonly menuLang = input.required<string>();
  readonly nextMenu = input.required<Menu | undefined>();
  readonly currentMenuDiets = input.required<string[]>();
  readonly currentMenuSource = input.required<string>();
  readonly isAgent = input.required<boolean>();
  readonly isManager = input.required<boolean>();
  readonly isTerminal = input.required<boolean | Terminal>();
  readonly showQuantity = input.required<boolean>();
  readonly showPortionSize = input.required<boolean>();
  readonly orders = input.required<Order[]>();
  readonly repeatOrders = input.required<RepeatOrder[]>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly featureConsistency = input.required<boolean>();
  readonly featureTexture = input.required<boolean>();
  readonly diets = input.required<SelectFieldModel[]>();
  @Input() set editedItem(val: ManagableItem) {
    this.showTime = this.showTime || !!val?.created_at;
    this._editedItem = val;
  }
  get editedItem(): ManagableItem {
    return this._editedItem;
  }
  @Input() set firstOrder(order: Order) {
    this._firstOrder = order;
    if (order?.created_at && !this.showTime) {
      this.showTime = true;
    }
  }
  get firstOrder(): Order {
    return this._firstOrder;
  }
  readonly isUserAllowOrderAllergies = input.required<boolean>();
  readonly isUserAllowOrderIntolerances = input.required<boolean>();
  readonly saveOrderLoading = input.required<boolean>();
  readonly selectedDate = input.required<string>();
  readonly showConsumerInfo = input.required<
    boolean | Terminal | ManagableItem
  >();
  readonly showConsumerInfoDetail = input.required<boolean>();
  readonly showHidden = input.required<
    boolean | Terminal | UnknownManagableItem
  >();
  readonly isOffline = input.required<boolean>();
  readonly quantityFieldIncrement = input.required<number>();
  readonly quantityFieldInput = input.required<boolean>();

  readonly sendOrdersEvent = output<{
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }>();
  readonly sendOrdersAndPrevEvent = output<{
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }>();
  readonly sendOrdersAndNextEvent = output<{
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }>();
  readonly selectionChanged = output();
  readonly applyDefaultOrder = output<{
    consumer: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }>();
  readonly applyDiets = output<string[]>();
  readonly fetchProductSheet = output<{
    token: string;
  }>();
  readonly applyTranslation = output<{
    translation: string;
    menu: Menu;
  }>();
  readonly searchAutocomplete = output<{
    value: string;
  }>();
  readonly showUnselectSnackMessage = output();
  readonly clearRepeatOrders = output<void>();

  langs = Object.keys(LANGUAGES);
  _editedItem: ManagableItem;
  _firstOrder: Order;
  showTime = false;
  nonRegularTranslations = [];

  array = Array;
  changedDescriptions: Record<number, Partial<Order>> = {}; // holds changed description banner data
  consumerAllergies: string[] = [];
  dietsScheduled: string[];
  hasItemsSelected = false;
  selectedOrders: Partial<Order>[] = [];
  selectedOrderHasVariants = false;
  selectedOrderHasShowDetails = false;
  selectedOrderMinQuantity = 1;
  showDescriptionsDeps = ['editedItem', 'isManager', 'isAgent', 'isTerminal'];
  loaderStyleTitle = {
    width: '350px',
    height: '30px',
    'max-width': 'calc(100% - 100px)',
  };
  loaderStyleName = {
    width: '100px',
    height: '25px',
  };
  loaderStyleDish = {
    width: '350px',
    height: '50px',
    'max-width': 'calc(100% - 100px)',
    'margin-right': '15px',
    'border-radius': '17px',
  };

  selectedEvent = new EventEmitter<SetOrdersItem[]>();
  deselectedEvent = new EventEmitter<string>();

  ngOnInit(): void {
    this.selectedEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((items) => this.handleSelectedOrder(items));
    this.deselectedEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeDescription();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const menu = this.menu();
    if ('menu' in changes && menu) {
      this.nonRegularTranslations = menu.translations.filter(
        (lang) => !this.langs.includes(lang),
      );
    }
    const targetObj = this.consumer() || this.editedItem;
    if (this.dietsScheduled === undefined) {
      this.determineWhetherDietsScheduled(targetObj, menu);
    }
    if (('consumer' in changes || 'editedItem' in changes) && targetObj) {
      this.consumerAllergies = [
        ...new Set([
          ...(targetObj.allergies ?? []),
          ...(targetObj.intolerances ?? []),
        ]),
      ];
    }
    if (
      'currentMenuSource' in changes &&
      this.currentMenuSource() &&
      targetObj &&
      ((this.isUserAllowOrderAllergies() === false &&
        targetObj.allergies?.length) ||
        (this.isUserAllowOrderIntolerances() === false &&
          targetObj.intolerances?.length))
    ) {
      setTimeout(() => this.findMenuItemsWithAllergens(targetObj));
    }
  }

  contentInit(): void {
    GLightbox({
      selector: `*[data-${lightboxSelector}]`,
      touchNavigation: true,
      keyboardNavigation: true,
      zoomable: false,
    });
  }

  findMenuItemsWithAllergens(target: ManagableItem | Consumer) {
    const targets = Array.from(
      document.querySelectorAll('[data-orderable], .section-line.level-4'),
    );
    targets.forEach((el) => {
      if (el.getAttribute('data-allergens')) {
        el.getAttribute('data-allergens')
          .split(' ')
          .filter(
            (allergen) =>
              (this.isUserAllowOrderAllergies() === false &&
                target.allergies?.includes(allergen)) ||
              (this.isUserAllowOrderIntolerances() === false &&
                target.intolerances?.includes(allergen)),
          )
          .forEach(() => {
            el.classList.add(DATA_ALLERGEN_CLASS);
          });
      }
    });
  }

  determineWhetherDietsScheduled(
    targetObj: Consumer | ManagableItem | undefined,
    menu: Menu | undefined,
  ) {
    if (!this.dietsScheduled && targetObj && menu) {
      const selectedDate = this.selectedDate();
      if (selectedDate && menu.apply_scheduled_diets[selectedDate]) {
        this.dietsScheduled =
          menu.apply_scheduled_diets[selectedDate].join(',') !==
          targetObj?.diets.join(',')
            ? menu.apply_scheduled_diets_detail[selectedDate]
            : null;
      } else {
        this.dietsScheduled =
          menu.apply_diets.join(',') !== targetObj?.diets.join(',')
            ? menu.apply_diets_detail
            : null;
      }
    }
  }

  setItemsSelected(): void {
    this.hasItemsSelected = true;
  }

  handleDescriptionDefaults(data: SetOrdersItem) {
    const {
      item,
      menudish_id: id,
      all_variants,
      show_details,
      set_description,
      set_consistency,
      set_texture,
      min_quantity,
      productSheet,
    } = data;
    if (this.changedDescriptions[id]) {
      return this.changedDescriptions[id];
    }
    const orderOrRepeatOrder = this.findOriginalOrder(data);
    if (orderOrRepeatOrder) {
      this.changedDescriptions[id] = {
        ...orderOrRepeatOrder,
        menudish_id: id,
        all_variants,
        show_details,
        productSheet,
      };
      return this.changedDescriptions[id];
    }
    const currentConsumer = this.editedItem || this.consumer();
    this.changedDescriptions[id] = {
      menudish_id: id,
      item,
      description: set_description || '',
      portion_size: currentConsumer?.portion_size || null,
      consistency:
        currentConsumer?.consistency ||
        (set_consistency as Consistency) ||
        null,
      texture: currentConsumer?.texture || (set_texture as Texture) || null,
      quantity: min_quantity || this.quantityFieldIncrement() || 1,
      variants: [],
      all_variants,
      show_details,
      productSheet,
    };
    return this.changedDescriptions[id];
  }

  handleSelectedOrder(items: SetOrdersItem[]): void {
    this.hasItemsSelected = true;
    this.selectionChanged.emit();
    const lastItem = items?.length ? items[items.length - 1] : undefined;
    if (lastItem) {
      this.selectedOrderHasShowDetails = lastItem?.show_details;
      this.selectedOrderHasVariants = !!lastItem?.all_variants?.length;
      this.selectedOrderMinQuantity = lastItem?.min_quantity || 1;
    }
    if (
      !this.editedItem &&
      !(this.isTerminal() && this.isManager()) &&
      !this.selectedOrderHasShowDetails &&
      !this.selectedOrderHasVariants &&
      !this.showQuantity() &&
      !this.showPortionSize()
    ) {
      return;
    }
    this.closeDescription();
    items?.forEach((data: SetOrdersItem) => {
      const changedDescription = this.handleDescriptionDefaults(data);
      this.selectedOrders.push(changedDescription);
    });
    this.checkShowDescriptions();
  }

  updateDescriptionIcons(): void {
    this.selectedOrders.forEach((item) => {
      if (item[`description`]) {
        const element: HTMLElement = document.querySelector(
          `.dish-line[data-id*="${item.menudish_id}"]`,
        );
        const dataset = element?.dataset;
        if (dataset) dataset[`desc`] = `true`;
      }
    });
  }

  applySearchFilter(data: string): void {
    if (data) {
      const targets = Array.from(
        document.querySelectorAll(
          '[data-orderable], .section-line.level-3, .section-line.level-4',
        ),
      );
      targets.forEach((el) => el.classList.add(MENU_SEARCH_CLASS));
      interface ElementData {
        element: Element;
        elementSection: Element;
        [key: string]: string | Element;
      }
      const structuredData: ElementData[] = [];
      Array.from(document.querySelectorAll(`[data-orderable]`)).forEach(
        (el) => {
          structuredData.push({
            element: el,
            elementSection: el.parentElement?.previousElementSibling,
            'data-name': el.getAttribute('data-name'),
            'data-name-base': el.getAttribute('data-name-base'),
            'data-variants': el.getAttribute('data-variants'),
            'data-winery': el.getAttribute('data-winery'),
            'data-separator-level1': el.getAttribute('data-separator-level1'),
            'data-separator-level2': el.getAttribute('data-separator-level2'),
            'data-separator-level3': el.getAttribute('data-separator-level3'),
            'data-separator-level1-base': el.getAttribute(
              'data-separator-level1-base',
            ),
            'data-separator-level2-base': el.getAttribute(
              'data-separator-level2-base',
            ),
            'data-separator-level3-base': el.getAttribute(
              'data-separator-level3-base',
            ),
          });
        },
      );
      const fuse = new Fuse(structuredData, {
        fieldNormWeight: 1,
        keys: [
          'data-name',
          'data-name-base',
          'data-variants',
          'data-winery',
          'data-separator-level1',
          'data-separator-level2',
          'data-separator-level3',
          'data-separator-level1-base',
          'data-separator-level2-base',
          'data-separator-level3-base',
        ],
        threshold: 0.4,
      });
      const fuseSearch = fuse.search(data);
      fuseSearch.forEach((el) => {
        el.item.element.classList.remove(MENU_SEARCH_CLASS);
        el.item.elementSection?.classList.remove(MENU_SEARCH_CLASS);
      });
    } else {
      const targets = Array.from(
        document.querySelectorAll(`.${MENU_SEARCH_CLASS}`),
      );
      targets.forEach((el) => el.classList.remove(MENU_SEARCH_CLASS));
    }
  }

  closeDescription(): void {
    if (this.selectedOrders?.length) {
      this._bottomSheet.dismiss();

      this.updateDescriptionIcons();
      this.selectedOrders = [];
      this.selectedOrderHasVariants = false;
      this.selectedOrderHasShowDetails = false;
    }
  }

  unSelectAll() {
    this.closeDescription();
    this.hasItemsSelected = false;
    this.closeDescription();
    const targets = Array.from(
      document.querySelectorAll('[data-ordered=true]'),
    );
    targets.forEach((el) => el.setAttribute('data-ordered', 'false'));
    targets.forEach((el) => el.setAttribute('data-updated', 'true'));
    this.showUnselectSnackMessage.emit();
  }

  checkShowDescriptions(): void {
    const showDescriptions = !!(
      (this.selectedOrders?.length &&
        (this.editedItem ||
          (this.isManager() && this.isTerminal()) ||
          this.showQuantity() ||
          this.showPortionSize())) ||
      this.selectedOrderHasVariants ||
      this.selectedOrderHasShowDetails
    );
    if (showDescriptions) {
      this._bottomSheet.open(OrderDescriptionsComponent, {
        hasBackdrop: false,
        scrollStrategy: new NoopScrollStrategy(),
        data: {
          consumer: this.consumer() || this.editedItem,
          featureConsistency: this.featureConsistency(),
          featureTexture: this.featureTexture(),
          isAgent: this.isAgent(),
          isManager: this.isManager(),
          isOffline: this.isOffline(),
          minQuantity: this.selectedOrderMinQuantity,
          showDescription: this.selectedOrderHasShowDetails,
          showOnlyPortionSize:
            this.showPortionSize() &&
            !(this.editedItem || (this.isManager() && this.isTerminal())),
          showOnlyQuantity:
            this.showQuantity() &&
            !(this.editedItem || (this.isManager() && this.isTerminal())),
          showOnlyVariants:
            !(this.editedItem || (this.isManager() && this.isTerminal())) &&
            this.selectedOrderHasVariants,
          orders: this.selectedOrders,
          quantityFieldIncrement: this.quantityFieldIncrement(),
          quantityFieldInput: this.quantityFieldInput(),
          lang: this.lang(),
          fetchProductSheet: this.fetchProductSheet.emit.bind(
            this.fetchProductSheet,
          ) as () => void,
          closeDescription: () => this.closeDescription(),
          searchAutocomplete: this.searchAutocomplete.emit.bind(
            this.searchAutocomplete,
          ) as () => void,
        } as OrderDescriptionsData,
      });
    }
  }

  saveOrders(operation: 'standard' | 'next' | 'prev' = 'standard'): void {
    this.closeDescription();
    const event = {
      standard: this.sendOrdersEvent,
      next: this.sendOrdersAndNextEvent,
      prev: this.sendOrdersAndPrevEvent,
    };
    const orders = this.orders();
    event[operation].emit({
      ...this.getSetOrderItems(),
      previousOrders: this.showTime && !!orders?.length ? orders : null,
    });
  }

  private findOriginalOrder(
    item: SetOrdersItem,
  ): Order | RepeatOrder | undefined {
    const repeatOrders = this.repeatOrders();
    if (repeatOrders?.length) {
      return repeatOrders.find((repeatOrder) =>
        isOrderMatchingElement(item, repeatOrder, this.menu().show_date),
      );
    }
    return this.orders().find(
      (order) =>
        order.menudish_id === item.menudish_id ||
        isOrderMatchingElement(item, order, this.menu().show_date),
    );
  }

  private getSetOrderItems(): { items: SetOrdersItem[] } {
    // getting selected orders from the DOM
    const items = extractOrderItems(
      Array.from(document.querySelectorAll(`[data-ordered=true]`)),
    );

    // copy changed descriptions to new order
    Object.entries(this.changedDescriptions).forEach(([key, value]) => {
      items.items.forEach((item) => {
        if (item.menudish_id === +key) {
          Object.assign(item, value);
        }
      });
    });

    // copy description and quantity from original order to new order, if:
    // a) they don't exist on the new order because they are not shown, e.g. consumer edits an order created by the admin
    // b) they are not reselected (e.g. variants)
    items.items = items.items.map((item) => {
      // avoid sending display-only fields to the backend
      const newItem = omit(item, [
        'all_variants',
        'show_details',
        'set_description',
        'set_consistency',
        'set_texture',
        'min_quantity',
        'productSheet',
      ]);

      // copy fields from the original order (saved or repeated) to the new order
      const originalOrder = this.findOriginalOrder(newItem);
      if (originalOrder) {
        Object.entries(originalOrder).forEach(([key, value]) => {
          if (
            !(key in newItem) &&
            [
              'description',
              'quantity',
              'portion_size',
              'consistency',
              'texture',
              'variants',
            ].some((f) => key.startsWith(f))
          ) {
            newItem[key] = value as string | number | OrderVariant[];
          }
        });
      }

      return newItem;
    });

    return items;
  }

  backToMenus(): void {
    this.closeDescription();
    this.router.navigate([`menus`], {
      queryParams: this.activatedRouter.snapshot.queryParams,
    });
  }

  updateOrders(repeatOrders: (RepeatOrder & { menudish_id: number })[]): void {
    repeatOrders.forEach((repeatOrder) => {
      this.changedDescriptions[repeatOrder.menudish_id] = {
        ...repeatOrder,
        menudish_id: repeatOrder.menudish_id,
      };
    });
  }
}
