import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { OrdersCompleteContainer } from 'src/app/features/containers/orders-complete/orders-complete.container';
import { clearOrdersPage } from 'src/app/store/orders/orders.actions';
import { isOrders as isOrdersUrl } from 'src/app/shared/utils.functions';

export const clearMenuHtmlGuard: CanDeactivateFn<OrdersCompleteContainer> = (
  _component,
  _currentRoute,
  _currentState,
  nextState,
) => {
  if (!isOrdersUrl(nextState?.url)) inject(Store).dispatch(clearOrdersPage());
  return true;
};
