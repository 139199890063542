import { Routes } from '@angular/router';
import { ConfirmEmailContainer } from './features/authentication/confirm-email/confirm-email.container';
import { LoginContainer } from './features/authentication/login/login.container';
import { MenusContainer } from './features/containers/menus/menus.container';
import { OrdersCompleteContainer } from './features/containers/orders-complete/orders-complete.container';
import { OrdersContainer } from './features/containers/orders/orders.container';
import { RedirectContainer } from './features/containers/redirect/redirect.container';
import { RegistrationContainer } from './features/authentication/registration/registration.container';
import { ResetPasswordContainer } from './features/authentication/reset-password/reset-password.container';
import { VerifyEmailComponent } from './features/authentication/verify-email/verify-email.container';
import { AuthLayoutComponent } from './layout/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/layouts/main-layout/main-layout.component';
import { clearMenuHtmlGuard } from './shared/guards/clear-menu-html.guard';
import { deactivateOrdersGuard } from './shared/guards/deactivate-orders.guard';
import { loginGuard } from './shared/guards/login.guard';
import { emailVerificationGuard } from './shared/guards/verified.guard';
import { menusResolver } from './shared/resolvers/menus.resolver';
import { ordersCompleteResolver } from './shared/resolvers/orders-complete.resolver';
import { ordersResolver } from './shared/resolvers/orders.resolver';
import { redirectResolver } from './shared/resolvers/redirect.resolver';

export const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [loginGuard],
    children: [
      {
        path: 'login',
        component: LoginContainer,
        canActivate: [loginGuard],
      },
      {
        path: 'registration',
        canActivate: [loginGuard],
        component: RegistrationContainer,
      },
      {
        path: 'registration/:key',
        canActivate: [loginGuard],
        component: RegistrationContainer,
        data: { invitation: true },
      },
      {
        path: 'reset-password',
        component: ResetPasswordContainer,
        canActivate: [loginGuard],
      },
      {
        path: 'reset/:uid/:token',
        component: ResetPasswordContainer,
        canActivate: [loginGuard],
        data: { confirm: true },
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [loginGuard],
    children: [
      {
        path: 'menus',
        component: MenusContainer,
        canActivate: [emailVerificationGuard],
        resolve: { menus: menusResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'orders/:menuId/:date/complete',
        component: OrdersCompleteContainer,
        canActivate: [emailVerificationGuard],
        canDeactivate: [clearMenuHtmlGuard],
        resolve: { orders: ordersCompleteResolver },
      },
      {
        path: 'orders/:menuId/:date/:consumer',
        component: OrdersContainer,
        canActivate: [emailVerificationGuard],
        resolve: { orders: ordersResolver },
        canDeactivate: [deactivateOrdersGuard],
      },
      {
        path: 'orders/:menuId/:date:/:created_by',
        component: OrdersContainer,
        canActivate: [emailVerificationGuard],
        resolve: { orders: ordersResolver },
        canDeactivate: [deactivateOrdersGuard],
      },
      {
        path: 'orders/:menuId/:date',
        component: OrdersContainer,
        canActivate: [emailVerificationGuard],
        resolve: { orders: ordersResolver },
        canDeactivate: [deactivateOrdersGuard],
      },
      {
        path: 'redirect',
        component: RedirectContainer,
        canActivate: [emailVerificationGuard],
        resolve: { redirect: redirectResolver },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent,
        canActivate: [emailVerificationGuard],
        data: { requiresVerified: false },
      },
      {
        path: 'confirm-email/:key',
        component: ConfirmEmailContainer,
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./features/settings/settings.routes')
            .then((m) => m.routes)
            .catch(() => {
              window.location.reload();
              return [];
            }),
      },
      {
        path: 'terminal',
        loadChildren: () =>
          import('./features/terminal/terminal.routes')
            .then((m) => m.routes)
            .catch(() => {
              window.location.reload();
              return [];
            }),
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./features/managing/managing.routes')
            .then((m) => m.managingRoutes)
            .catch(() => {
              window.location.reload();
              return [];
            }),
      },
      {
        path: '',
        redirectTo: 'menus',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'menus',
      },
    ],
  },
];
