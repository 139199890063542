import {
  Component,
  DestroyRef,
  inject,
  input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { distinctUntilChanged } from 'rxjs';
import { ManagableAccess } from 'src/app/shared/models/consumers';
import { UnknownManagableItem } from 'src/app/shared/models/misc';
import { ManagableOrder } from 'src/app/shared/models/orders';

@Component({
  selector: 'win-quickedit-consumer',
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    TranslocoPipe,
    MatButtonModule,
  ],
  templateUrl: './quickedit-consumer.component.html',
  styleUrl: './quickedit-consumer.component.scss',
})
export class QuickeditConsumerComponent implements OnChanges, OnInit {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);

  readonly item = input.required<
    ManagableAccess | ManagableOrder | UnknownManagableItem
  >();
  readonly isAgent = input.required<boolean>();
  readonly isManager = input.required<boolean>();
  readonly staffOrdertaking = input.required<boolean>();

  readonly changeCheckoutDate = output<{
    data: {
      checkout: string;
    };
    id: number;
    url: string;
  }>();

  checkoutDate = new FormControl('');
  isPastCheckout = false;
  minDate = new Date();
  initDate = new Date();

  ngOnChanges(changes: SimpleChanges): void {
    const item = this.item();
    const isManager = this.isManager();
    if (
      ('item' in changes && item && isManager) ||
      ('isManager' in changes && isManager && item)
    ) {
      this.isPastCheckout = this.checkIsPastCheckout();
    }
  }

  ngOnInit(): void {
    this.checkoutDate.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((date) =>
        this.changeCheckoutDate.emit({
          data: {
            checkout: this.dateAdapter.format(new Date(date), 'yyyy-MM-dd'),
          },
          id: this.item().id,
          url: this.item().url,
        }),
      );
  }

  checkIsPastCheckout(): boolean {
    const item = this.item();
    const checkoutDate = item?.checkout;
    if (!checkoutDate) return undefined;
    const checkout = new Date(checkoutDate);

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const offset = date.getTimezoneOffset();
    const today = new Date(date.getTime() - offset * 60 * 1000);
    const result = today.getTime() > checkout.getTime();
    if (result) {
      this.checkoutDate.patchValue(item?.checkout, { emitEvent: false });
    }
    return result;
  }
}
