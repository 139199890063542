import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslocoPipe } from '@jsverse/transloco';
import { PasswordValidators } from 'ngx-validators';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { TERMS_LINKS } from 'src/app/app.config';
import {
  checkInvitation,
  register,
} from 'src/app/store/authentication/authentication.actions';
import { AuthenticationServiceBuilder } from 'src/app/shared/services/authentication/authentication.builder';

import { InvitationResult } from '../../../shared/models/user';
import { AsyncEmailValidator } from '../../../shared/validators/async-email.validator';
import { RegistrationForm } from '../../../shared/models/authentication';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { InviterInfoComponent } from './inviter-info/inviter-info.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { globalFeature } from 'src/app/store/global/global.state';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';

@Component({
  selector: 'win-registration',
  templateUrl: './registration.container.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    '../login/simple-login/simple-login.component.scss',
    './registration.container.scss',
  ],
  imports: [
    MatCardModule,
    InviterInfoComponent,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    AsyncPipe,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class RegistrationContainer implements OnInit {
  private readonly authServiceBuilder = inject(AuthenticationServiceBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private readonly route = inject(ActivatedRoute);

  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  invitationErrorMsg$ = this.store.pipe(
    select(authenticationFeature.selectInvitationError),
  );
  invitationResult$ = this.store
    .pipe(select(authenticationFeature.selectInvitationResult))
    .pipe(
      filter((v) => !!v),
      tap((data) => this.invitationSuccess(data)),
    );
  spinnerState$ = this.store.pipe(
    select(authenticationFeature.selectRegisterSpinner),
  );

  registrationForm = new FormGroup(
    {
      first_name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(24),
      ]),
      last_name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(24),
      ]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: null,
        updateOn: 'blur',
      }),
      terms: new FormControl(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
      password1: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ]),
      password2: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ]),
    },
    {
      validators: [
        PasswordValidators.mismatchedPasswords(`password1`, `password2`),
      ],
    },
  );
  checkingEmail$: Observable<boolean>;
  language: InterfaceLanguage;
  termsLinks = TERMS_LINKS;
  invitation: boolean;

  ngOnInit(): void {
    // Language
    this.globalLanguage$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.language = val;
      });

    // Invitation
    this.invitation = !!this.route.snapshot.data?.invitation;
    const key = this.route.snapshot.params.key as string;
    if (this.invitation) this.store.dispatch(checkInvitation({ key }));

    // Form
    this.buildForm();
    this.checkingEmail$ = this.registrationForm.statusChanges.pipe(
      map(() => this.registrationForm.status === 'PENDING'),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  buildForm(): void {
    if (this.invitation) {
      this.registrationForm.disable();
    } else {
      this.registrationForm.controls?.email.setAsyncValidators(
        AsyncEmailValidator.createValidator(
          this.authServiceBuilder,
          this.store,
        ),
      );
      this.registrationForm.controls?.email.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    if (!this.registrationForm.valid) return;

    // Ensure terms error would be shown after pressing submit
    this.registrationForm.controls?.terms.markAsTouched();

    // Process data
    const formValue = this.registrationForm.value as RegistrationForm;
    formValue.language = this.language;
    if (this.invitation) {
      formValue.email = this.registrationForm.controls.email.value;
    }
    this.store.dispatch(register({ payload: formValue }));
  }

  invitationSuccess(data: InvitationResult): void {
    this.registrationForm.enable();
    this.registrationForm.patchValue(
      {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
      },
      { emitEvent: false },
    );
    this.registrationForm.get(`email`).disable();
  }
}
