import { Component, Input } from '@angular/core';

import { InvitationResult } from '../../../../shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'win-inviter-info',
  templateUrl: './inviter-info.component.html',
  styleUrls: ['./inviter-info.component.scss'],
  imports: [TranslocoPipe],
})
export class InviterInfoComponent {
  @Input() set data(val: InvitationResult) {
    this.params = {
      name: `${val?.invitation.inviter.first_name} ${val?.invitation.inviter.last_name}`,
      org_name: val?.invitation.organisation.name,
    };
  }

  params: object;
}
