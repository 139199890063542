import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  NgxOtpInputComponent,
  NgxOtpInputComponentOptions,
} from 'ngx-otp-input';
import { delay, filter, of, switchMap, tap } from 'rxjs';
import {
  retrieveUuid,
  validateKey,
} from 'src/app/store/authentication/authentication.actions';
import { clearFormErrors } from 'src/app/store/global/global.actions';
import { NON_FIELD_ERRORS } from 'src/app/shared/directives/server-form-error.directive';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormWithErrorsComponent } from '../../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';
import { globalFeature } from 'src/app/store/global/global.state';

@Component({
  selector: 'win-unauthenticated-login',
  templateUrl: './unauthenticated-login.component.html',
  styleUrls: ['./unauthenticated-login.component.scss'],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    NgxOtpInputComponent,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class UnauthenticatedLoginComponent implements OnInit {
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  readonly spinnerState = input.required<boolean>();
  readonly aggregatedOrderFeature = input.required<boolean>();
  readonly unauthInvalidUrlMessage = input.required<boolean>();

  consumerForm = new FormGroup({
    first_name: new FormControl<string>(null),
    last_name: new FormControl<string>(null),
    name: new FormControl<string>(null),
  });
  readonly consumerFormId = 'unauthenticated-form';
  key: string;
  otpInputConfig: NgxOtpInputComponentOptions = {
    otpLength: 3,
    inputMode: 'text',
    regexp: /^[a-zA-ZäöüßÄÖÜẞ\u00C0-\u017F]+$/,
  };
  roomNo: string;
  showLastName = false;

  readonly codeInputFirstName =
    viewChild<NgxOtpInputComponent>('firstNameCodeInput');
  readonly codeInputLastName =
    viewChild<NgxOtpInputComponent>('lastNameCodeInput');
  readonly codeInputName = viewChild<NgxOtpInputComponent>('nameCodeInput');

  constructor() {
    this.key = this.activatedRouter.snapshot.queryParams['key'] as string;
    this.roomNo = this.activatedRouter.snapshot.queryParams[
      'auth_room'
    ] as string;
  }

  ngOnInit(): void {
    this.store.dispatch(
      validateKey({
        key: this.key,
      }),
    );
    this.store
      .pipe(select(globalFeature.selectFormErrors))
      .pipe(
        filter((error) => !!error?.[NON_FIELD_ERRORS]),
        tap(() => {
          if (this.consumerForm)
            this.consumerForm.reset({}, { emitEvent: false });
          if (this.aggregatedOrderFeature()) {
            const codeInputName = this.codeInputName();
            if (codeInputName) codeInputName.reset();
          } else {
            const codeInputFirstName = this.codeInputFirstName();
            if (codeInputFirstName) codeInputFirstName.reset();
            const codeInputLastName = this.codeInputLastName();
            if (codeInputLastName) codeInputLastName.reset();
            setTimeout(() => {
              this.showLastName = false;
            });
          }
        }),
        switchMap(() => of(null).pipe(delay(10000))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.consumerForm.updateValueAndValidity();
        this.store.dispatch(clearFormErrors({ payload: NON_FIELD_ERRORS }));
      });
  }

  onCodeCompleted(code: string, name: string): void {
    if (name === 'first_name') {
      this.consumerForm.controls.first_name.setValue(code);
      this.codeInputFirstName().disabled = true;
      setTimeout(() => {
        this.codeInputFirstName().disabled = false;
        this.showLastName = true;
        this.cdr.detectChanges();
      }, 350);
    }
    if (name === 'last_name') {
      this.consumerForm.controls.last_name.setValue(code);
    }
    if (
      this.consumerForm.controls.first_name.value?.length &&
      this.consumerForm.controls.last_name.value?.length
    ) {
      const consumerName = {
        first_name: this.consumerForm.controls.first_name.value,
        last_name: this.consumerForm.controls.last_name.value,
      };
      this.store.dispatch(
        retrieveUuid({
          consumerName: consumerName,
          key: this.key,
          roomNo: this.roomNo,
        }),
      );
    }
  }

  onNameCodeCompleted(code: string): void {
    this.consumerForm.controls.name.setValue(code);
    const consumerName = {
      name: this.consumerForm.controls.name.value,
    };
    this.store.dispatch(
      retrieveUuid({
        consumerName: consumerName,
        key: this.key,
        roomNo: this.roomNo,
      }),
    );
  }
}
