<header class="header mat-elevation-z1" role="banner">
  <win-header
    [currentLang]="globalLanguage$ | async"
    [isOffline]="false"
    [logo]="null"
    [offlineOrderCount]="null"
    [simpleConsumer]="null"
    [showOfflineMode]="false"
    (languageChange)="languageChange($event)"
  >
  </win-header>
</header>

<main class="main-content" role="main">
  <router-outlet></router-outlet>
</main>

<footer class="footer" role="contentinfo">
  <win-footer [lang]="globalLanguage$ | async"></win-footer>
</footer>
