@if (isPastCheckout && item() && (isManager() || isAgent())) {
  <div class="past-checkout">
    <div>
      <mat-icon class="mat-icon">feedback</mat-icon>
      <div class="mat-body consumer-checked-out">
        {{ 'menus.consumer-checked-out' | transloco }}
      </div>
    </div>
    <p class="mat-body">{{ 'menus.past-checkout' | transloco }}</p>
    <div class="datepicker">
      <mat-form-field>
        <mat-label>
          {{ 'menus.checkout-date' | transloco }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [min]="minDate"
          [required]="true"
          [formControl]="checkoutDate"
          (click)="picker.open()"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker [startAt]="initDate"></mat-datepicker>
        <mat-error>{{
          'app.offline.enable.date-required' | transloco
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
} @else if (staffOrdertaking() || item()) {
  <p class="mat-body no-menu-available">
    {{ 'menus.no-menus' | transloco }}
  </p>
}
