import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  input,
  viewChild,
  output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTER_BACK_NAVIGATION } from 'src/app/shared/constants/misc';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  UnknownManagableItem,
} from 'src/app/shared/models/misc';
import { ManagableOrder } from 'src/app/shared/models/orders';
import { Terminal, UnauthConsumer } from 'src/app/shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { SaveStripeComponent } from '../../../../shared/components/save-stripe/save-stripe.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SubNavigationComponent } from '../../../../shared/components/sub-navigation/sub-navigation.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MenuComponent } from './menu/menu.component';
import { MenusCardsComponent } from './menus-cards/menus-cards.component';
import { ConsumerInfoComponent } from '../../../../shared/components/consumer-info/consumer-info.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { QuickeditConsumerComponent } from './quickedit-consumer/quickedit-consumer.component';

@Component({
  selector: 'win-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConsumerInfoComponent,
    MenusCardsComponent,
    MenuComponent,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgTemplateOutlet,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    SaveStripeComponent,
    SubNavigationComponent,
    TranslocoPipe,
    MatButtonModule,
    QuickeditConsumerComponent,
  ],
})
export class MenusComponent {
  readonly menus = input.required<Menu[]>();
  readonly dailyMenus = input.required<Menu[]>();
  readonly specialMenus = input.required<Menu[]>();
  readonly isConsumer = input.required<boolean>();
  readonly isKdsUser = input.required<boolean>();
  readonly isServiceUser = input.required<boolean>();
  readonly isTerminal = input.required<boolean | Terminal>();
  readonly isAgent = input.required<boolean>();
  readonly isManager = input.required<boolean>();
  readonly isOffline = input.required<boolean>();
  readonly isUnauth = input.required<boolean>();
  readonly fullName = input.required<string>();
  readonly item = input.required<
    ManagableAccess | ManagableOrder | UnknownManagableItem
  >();
  readonly offlineDate = input.required<string>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly staffOrdertaking = input.required<boolean>();
  readonly showThirdMenuCard = input.required<boolean>();
  readonly goBackPage = input.required<'accesses' | 'consumers' | 'orders'>();
  readonly consumer = input.required<Consumer>();
  readonly unauthConsumer = input.required<UnauthConsumer>();
  readonly showConsumerInfo = input.required<
    boolean | Terminal | ManagableItem
  >();
  readonly showConsumerInfoDetail = input.required<boolean>();

  readonly logout = output<void>();
  readonly selectMenu = output<Menu>();
  readonly selectMenuShowDatepicker = output<Menu>();
  readonly goBack = output<void>();
  readonly changeCheckoutDate = output<{
    data: {
      checkout: string;
    };
    id: number;
    url: string;
  }>();

  small = window.innerWidth <= 400;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.small = window.innerWidth <= 400;
  }

  readonly menusRef = viewChild('menusRef', { read: ElementRef });

  goBackTranslations = ROUTER_BACK_NAVIGATION;

  scrollToMenus(): void {
    (this.menusRef()?.nativeElement as HTMLElement).scrollIntoView({
      behavior: 'smooth',
    });
  }
}
