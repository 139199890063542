<ng-container *ngTemplateOutlet="isTerminal() && stripe"></ng-container>

@if (item() && goBackPage()) {
  <win-sub-navigation
    [label]="goBackTranslations[goBackPage()] | transloco"
    (navigate)="goBack.emit()"
  ></win-sub-navigation>
}

@if (showConsumerInfo()) {
  <win-consumer-info
    class="mat-body consumer-info"
    [consumer]="consumer() || item() || unauthConsumer()"
    [isOffline]="isOffline()"
    [lang]="lang()"
    [showDetail]="showConsumerInfoDetail()"
  ></win-consumer-info>
}

<div class="wrapper">
  @if (!item() && !isTerminal()) {
    <div class="header">
      @if (fullName()) {
        <h1 class="mat-h2">
          {{ fullName() }}
        </h1>
      }
      @if ((isManager() || isAgent()) && !item() && !isTerminal()) {
        <p class="mat-body instruction">
          {{ 'menus.instruction-admin' | transloco }}
        </p>
        <win-menus-cards
          class="menus-cards"
          [showThirdCard]="menus()?.length && showThirdMenuCard()"
          [isManager]="isManager()"
          [isKdsUser]="isKdsUser()"
          [isServiceUser]="isServiceUser()"
          (scrollToMenus)="scrollToMenus()"
        ></win-menus-cards>
        @if (staffOrdertaking()) {
          <hr />
        }
      }
    </div>
  }

  @if (staffOrdertaking() || isTerminal() || isConsumer() || item()) {
    <section class="menus-wrapper" #menusRef>
      @if (menus()?.length) {
        @if (specialMenus().length) {
          <div class="menus">
            <h2 class="mat-h2">{{ 'menus.special' | transloco }}:</h2>
            @if (isOffline()) {
              <p class="mat-body offline-notice">
                {{ 'menus.diets-offline-notice' | transloco }}
              </p>
            }
            @for (menu of specialMenus(); track menu) {
              <win-menu
                class="menu"
                [lang]="lang()"
                [menu]="menu"
                (openMenu)="
                  (menu.has_orders || menu.can_order) &&
                    selectMenuShowDatepicker.emit(menu)
                "
              ></win-menu>
            }
          </div>
        }
        @if (dailyMenus().length) {
          <div class="menus">
            <h2 class="mat-h2">{{ 'menus.daily' | transloco }}:</h2>
            @for (menu of dailyMenus(); track menu) {
              <win-menu
                class="menu"
                [lang]="lang()"
                [menu]="menu"
                (openMenu)="
                  (menu.has_orders || menu.can_order) && selectMenu.emit(menu)
                "
              ></win-menu>
            }
          </div>
        }
      } @else if (menus()?.length === 0) {
        <win-quickedit-consumer
          [item]="item()"
          [isAgent]="isAgent()"
          [isManager]="isManager()"
          [staffOrdertaking]="staffOrdertaking()"
          (changeCheckoutDate)="changeCheckoutDate.emit($event)"
        ></win-quickedit-consumer>
      } @else {
        <ngx-skeleton-loader
          class="skeleton-title"
          [theme]="{
            width: '350px',
            height: '30px',
            'max-width': 'calc(100% - 100px)',
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="skeleton"
          count="7"
          [theme]="{
            width: small ? '100%' : '310px',
            height: '106px',
            margin: small ? '10px 0' : '10px 0',
          }"
        ></ngx-skeleton-loader>
      }
    </section>
  }
</div>

<ng-container *ngTemplateOutlet="isTerminal() && stripe"></ng-container>

<ng-template #stripe>
  <win-save-stripe class="save-stripe">
    <button
      mat-flat-button
      color="primary"
      class="fill-parent"
      mat-button
      (click)="logout.emit()"
    >
      {{ 'shared.done' | transloco }}
    </button>
  </win-save-stripe>
</ng-template>
